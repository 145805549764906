import React from "react"
import Reveal from "react-reveal/Reveal"
import Features from "../components/Features"
import IntegrationsPreview from "../components/IntegrationsPreview"
import PreregisterForm from "../components/Preregsiter"
import Layout from "../components/layout"
import SEO from "../components/seo"
import managerFeatures from "../../content/manager-features.yaml"

// Why would a manager want to approve this expense?
const WhyManagerPage = () => {
  return (
    <Layout navClass="w_menu" headerClass="transparent_header">
      <SEO title="Why use HookActions?" />

      <section className="breadcrumb_area">
        <img
          className="breadcrumb_shap"
          src={require("../images/breadcrumb/banner_bg.png")}
          alt=""
        />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
                Why use HookActions?
              </h1>
            </Reveal>
            <p className="f_400 w_color f_size_16 l_height26">
              The single source of truth for all your organization's third-party
              webhooks.
            </p>
          </div>
        </div>
      </section>

      <section className="prototype_service_info">
        <div className="symbols-pulse active">
          <div className="pulse-1"></div>
          <div className="pulse-2"></div>
          <div className="pulse-3"></div>
          <div className="pulse-4"></div>
          <div className="pulse-x"></div>
        </div>
        <div className="container">
          <h2 className="f_size_30 f_600 t_color3 l_height45 text-center mb_90">
            HookActions is built by developers for developers.
          </h2>
          <Features features={managerFeatures.features} />
        </div>
      </section>
      <div className="container py-4">
        <p>
          With a developer paid $100,000/year, spending 1 month building a new
          integration would cost around $8,000.
        </p>
        <p>
          Counting maintenance of a few days per week comes out to $1,000 to
          $2,000.
        </p>
      </div>
      <IntegrationsPreview />
      <div className="py-5" />
      <PreregisterForm />
    </Layout>
  )
}

export default WhyManagerPage
